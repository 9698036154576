import { Controller } from '@hotwired/stimulus';
import qs from 'qs';

export default class extends Controller {
  static targets = ['suggestion', 'form', 'metaForm', 'results', 'marketplace', 'scategories', 'scope', 'category', 'condition', 'type', 'restricted', 'calibers', 'dealers', 'price', 'country', 'geo', 'discount', 'availability', 'lefty', 'resetAll', 'sort', 'sortDistance'];
  static values  = {
    queriable: {
      type: Boolean,
      default: false,
    },
    windowTitle: String,
  };

  get appController () {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('body'), 'app');
  }

  connect () {
    this.filters = [];
    this.resets  = {};

    this.searchbox = document.querySelector('.searchbox input');

    this.useFilter.bind(this);
    this.addReset.bind(this);

    this.query();

    this.suggestion();

    this.marketplace();
    this.condition();
    this.type();
    this.restricted();
    this.calibers();
    this.dealers();
    this.price();
    this.country();
    this.geo();
    this.discount();
    this.availability();
    this.lefty();

    this.scategories();

    this.sort();

    // this.autoPage();
  }

  resultsTargetConnected () {
    !this.resultsTargetConnectedCounter ? this.resultsTargetConnectedCounter = 1 : this.resultsTargetConnectedCounter += 1;

    window.$Gunfinder.evalAdHunter();
    
    this.page();

    if (this.resultsTargetConnectedCounter > 1)
      this.urlHistory();
  }

  urlHistory () {
    const obj = JSON.parse(atob(this.resultsTarget.dataset.filter));

    if (obj.query === '*')
      delete obj.query;

    if (Object.keys(obj).length === 0)
      return;
    
    const query = qs.stringify(obj, { arrayFormat: 'brackets' });
    window.history.replaceState(null, null, `${window.location.pathname}?${query}`)
  }

  addReset (name, exec) {
    this.resets[name] = () => {
      exec();
      this.useFilter(name, false);
    };

    const el = this[`${name}Target`].querySelector('i.reset');

    if (el) {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        this.resets[name]();
        el.classList.add('d-none');
        this.search();
      });
    }
  }

  useFilter (name, used) {
    const target = name.charAt(0).toUpperCase() + name.slice(1);

    if (this[`has${target}Target`]) {
      const el = this[`${name}Target`].querySelector('i.reset');

      if (used) {
        if (el) {
          el.classList.remove('d-none');
        }

        this.filters.push(name);
      } else {
        if (el) {
          el.classList.add('d-none');
        }
        
        this.filters = this.filters.filter((val) => { 
          return val !== name;
        });
      }
    }

    this.resetAllTarget.disabled = this.filters.length === 0;
    if (this.resetAllTarget.disabled) {
      this.resetAllTarget.classList.replace('btn-danger', 'btn-light');
    } else {
      this.resetAllTarget.classList.replace('btn-light', 'btn-danger');
    }
  }


  setQueryValue () {
    const value = (this.searchbox.value || '').trim();

    if (value.length <= 1) return;

    this.formTarget.querySelector('input[name="query"]').value     = value;

    if (this.hasMetaFormTarget) {
      this.metaFormTarget.querySelector('input[name="query"]').value = value;
    }
  }

  query () {
    if (!this.queriableValue)
      return;

    this.searchbox.addEventListener('input', () => {
      this.setQueryValue();
      this.search({ debounce: 300, scrollIntoView: false });
      this.metaSearch({ debounce: 300 });
    });

    this.searchbox.addEventListener('search-autocomplete.selected', () => {
      this.setQueryValue();
      this.search();
      this.metaSearch();
    });
  }

  suggestion () {
    if (!this.hasSuggestionTarget) return;

    this.suggestionTarget.querySelector('a').addEventListener('click', (e) => {
      e.preventDefault();

      this.searchbox.value = e.currentTarget.innerText;
      this.searchbox.dispatchEvent(new Event('input'));
    });
  }

  setScope (e) {
    this.scategoriesTarget.querySelector('input[name="category"]').value = null;
    this.scategoriesTarget.querySelector('input[name="scope"]').value    = e.params.scope;

    this.useFilter('scategories', true);

    this.search();
  }

  setCategory (e) {
    this.scategoriesTarget.querySelector('input[name="scope"]').value    = null;
    this.scategoriesTarget.querySelector('input[name="category"]').value = e.params.categoryId ? e.params.categoryId : null;

    this.useFilter('scategories', !!e.params.categoryId);

    this.search();
  }

  scategories () {
    this.scategoriesTarget.querySelector('turbo-frame').addEventListener('turbo:frame-load', () => {
      this.scopesAndCategories();

      this.addReset('scategories', () => {
        this.scategoriesTarget.querySelector('a.reset').click();
      });
    });
  }

  scopesAndCategories () {
    this.useFilter('scategories', this.hasScopeTarget || this.hasCategoryTarget);
  }

  marketplace () {
    this.addReset('marketplace', () => {
      this.marketplaceTarget.querySelector('input[type="checkbox"]').checked = false;
    });

    this.useFilter('marketplace', this.marketplaceTarget.querySelector('input[type="checkbox"]').checked);

    this.marketplaceTarget.querySelector('input[type="checkbox"]').addEventListener('change', () => {
      this.useFilter('marketplace', this.marketplaceTarget.querySelector('input[type="checkbox"]').checked);
      this.search();
    });
  }

  condition () {
    this.addReset('condition', () => {
      this.conditionTarget.querySelector('select').dispatchEvent(new Event('reset'));
    });

    this.useFilter('condition', this.conditionTarget.querySelector('select option:checked').value !== '');

    this.conditionTarget.querySelector('select').addEventListener('change', () => {
      this.useFilter('condition', this.conditionTarget.querySelector('select option:checked').value !== '');
      this.search();
    });
  }

  type () {
    this.addReset('type', () => {
      this.typeTarget.querySelector('select').dispatchEvent(new Event('reset'));
    });

    this.useFilter('type', this.typeTarget.querySelector('select option:checked').value !== '');

    this.typeTarget.querySelector('select').addEventListener('change', () => {
      this.useFilter('type', this.typeTarget.querySelector('select option:checked').value !== '');
      this.search();
    });
  }

  restricted () {
    this.addReset('restricted', () => {
      this.restrictedTarget.querySelector('select').dispatchEvent(new Event('reset'));
    });

    this.useFilter('restricted', this.restrictedTarget.querySelector('select option:checked').value !== '');

    this.restrictedTarget.querySelector('select').addEventListener('change', () => {
      this.useFilter('restricted', this.restrictedTarget.querySelector('select option:checked').value !== '');
      this.search();
    });
  }

  calibers () {
    this.addReset('calibers', () => {
      this.calibersTarget.querySelector('select').dispatchEvent(new Event('reset'));
    });

    this.useFilter('calibers', this.calibersTarget.querySelectorAll('select option:checked').length > 0);

    this.calibersTarget.querySelector('select').addEventListener('change', () => {
      this.useFilter('calibers', this.calibersTarget.querySelectorAll('select option:checked').length > 0);
      this.search();
    });
  }

  dealers () {
    this.addReset('dealers', () => {
      this.dealersTarget.querySelector('select').dispatchEvent(new Event('reset'));
    });

    this.useFilter('dealers', this.dealersTarget.querySelectorAll('select option:checked').length > 0);

    this.dealersTarget.querySelector('select').addEventListener('change', () => {
      this.useFilter('dealers', this.dealersTarget.querySelectorAll('select option:checked').length > 0);
      this.search();
    });
  }

  price () {
    const
      min   = this.priceTarget.querySelector('input[name="price[min]"]'),
      max   = this.priceTarget.querySelector('input[name="price[max]"]')
    ;

    this.addReset('price', () => {
      min.value = '';
      max.value = '';
    });

    this.useFilter('price', min.value.length > 0 || max.value.length > 0);

    for (const el of [min, max]) {
      el.addEventListener('input', () => {
        el.value = el.value.replace(/[^\d]/, '');
        this.useFilter('price', min.value.length > 0 || max.value.length > 0);

        this.search();
      });
    }
  }

  country () {
    const toggleGeo = (disable) => {
      if (disable) {
        this.geoTarget.querySelector('input[name="geo[postal_code]"]').value = '';
        this.resets.geo();
      }

      this.geoTarget.querySelector('input[name="geo[postal_code]"]').disabled = disable;
      this.geoTarget.querySelector('select[name="geo[radius]"]').disabled     = disable;
      this.geoTarget.querySelector('select[name="geo[radius]"]').dispatchEvent(new Event(disable ? 'disable' : 'enable'));
    };

    this.addReset('country', () => {
      this.countryTarget.querySelector('select').dispatchEvent(new Event('reset'));
      toggleGeo(true);
    });

    this.useFilter('country', this.countryTarget.querySelector('select option:checked').value !== '');

    if (this.countryTarget.querySelector('select option:checked').value != '') {
      toggleGeo(false);
    }

    this.countryTarget.querySelector('select').addEventListener('change', () => {
      const used = this.countryTarget.querySelector('select option:checked').value !== '';
      this.useFilter('country', used);
      toggleGeo(!used);

      this.search();
    });
  }

  geo () {
    const
      used = () => {
        return this.geoTarget.querySelector('input').value.length > 0 ||
          this.geoTarget.querySelector('select[name="geo[radius]"] option:checked').value != '25'
        ;
      },
      validPostalCode = () => {
        const pattern = this.countryTarget.querySelector('select option:checked').value == 'DE' ? /^\d{5}$/ : /^\d{4}$/;
        return pattern.test(this.geoTarget.querySelector('input').value);
      }
    ;

    this.addReset('geo', () => {
      this.geoTarget.querySelector('input').value = '';
      for (const el of this.geoTarget.querySelectorAll('select')) {
        el.dispatchEvent(new Event('reset'));
      }

      this.sortDistanceTarget.disabled = true;
      if (this.formTarget.querySelector('input[name="sort"]').value === 'distance-asc') this.resets.sort();
    });

    this.useFilter('geo', used());

    this.geoTarget.querySelector('input').addEventListener('input', () => {
      this.useFilter('geo', used());

      if (validPostalCode() || this.geoTarget.querySelector('input').value.length == 0) {
        this.search();
        
        if (this.geoTarget.querySelector('input').value.length == 0) {
          this.sortDistanceTarget.disabled = true;

          if (this.formTarget.querySelector('input[name="sort"]').value === 'distance-asc') this.resets.sort();
        } else {
          this.sortDistanceTarget.disabled = false;
          this.sortDistanceTarget.parentNode.dispatchEvent(new Event('change'));
        }
      }
    });

    for (const el of this.geoTarget.querySelectorAll('select')) {
      el.addEventListener('change', () => {
        this.useFilter('geo', used());

        if (validPostalCode()) {
          this.search();
        }
      });
    }
  }

  discount () {
    this.addReset('discount', () => {
      this.discountTarget.querySelector('select').dispatchEvent(new Event('reset'));
    });

    this.useFilter('discount', this.discountTarget.querySelector('select option:checked').value !== '');

    this.discountTarget.querySelector('select').addEventListener('change', () => {
      this.useFilter('discount', this.discountTarget.querySelector('select option:checked').value !== '');
      this.search();
    });
  }

  availability () {
    this.addReset('availability', () => {
      this.availabilityTarget.querySelector('input[type="checkbox"]').checked = false;
    });

    this.useFilter('availability', this.availabilityTarget.querySelector('input[type="checkbox"]').checked);

    this.availabilityTarget.querySelector('input[type="checkbox"]').addEventListener('change', () => {
      this.useFilter('availability', this.availabilityTarget.querySelector('input[type="checkbox"]').checked);
      this.search();
    });
  }

  lefty () {
    this.addReset('lefty', () => {
      this.leftyTarget.querySelector('input[type="checkbox"]').checked = false;
    });

    this.useFilter('lefty', this.leftyTarget.querySelector('input[type="checkbox"]').checked);

    this.leftyTarget.querySelector('input[type="checkbox"]').addEventListener('change', () => {
      this.useFilter('lefty', this.leftyTarget.querySelector('input[type="checkbox"]').checked);
      this.search();
    });
  }

  page () {
    for (const el of document.querySelectorAll('.pagination a.page-link')) {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        const match = el.getAttribute('href').match(/page=(\d+)/);
        if (match) {
          this.formTarget.querySelector('input[name="page"]').value = match[1];
          this.search({ resetPage: false });
        }
      });
    }
  }

  autoPage () {
    window.onscroll = (e) => {
      if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
        alert('bottom');
      }
    };
  }

  sort () {
    if (!this.hasSortTarget)
      return;

    this.addReset('sort', () => {
      this.sortTarget.querySelector('select').dispatchEvent(new Event('reset'));
    });

    this.useFilter('sort', this.sortTarget.querySelector('select option:checked')?.value || '' !== '');

    this.sortTarget.querySelector('select').addEventListener('change', () => {
      this.useFilter('sort', this.sortTarget.querySelector('select option:checked').value !== '');
      this.formTarget.querySelector('input[name="sort"]').value = this.sortTarget.querySelector('select option:checked').value;
      this.search();
    });
  }


  search ({ debounce = 0, resetPage = true, scrollIntoView = true } = {}) {
    window.clearTimeout(this.searchTimer);

    this.searchTimer = window.setTimeout(() => {
      if (resetPage) {
        this.formTarget.querySelector('input[name="page"]').value = 1;
      }

      this.formTarget.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true })); // yes ...
      
      if (scrollIntoView) {
        if (this.queriableValue) {
          window.scrollTo({ top: 0 });
        } else {
          this.element.scrollIntoView();
        }
      }

      document.body.dispatchEvent(new CustomEvent('addSearchHistory', { detail: { query: this.searchbox.value } }));

      if (this.queriableValue) {
        document.title = this.windowTitleValue.replace('%{query}', this.searchbox.value);
      }
    }, debounce);
  }

  metaSearch ({ debounce = 0 } = {}) {
    if (!this.hasMetaFormTarget) return;
    window.clearTimeout(this.metaSearchTimer);

    this.metaSearchTimer = window.setTimeout(() => {
      if(!this.hasMetaFormTarget) return;
      this.metaFormTarget.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true })); // yes ...
    }, debounce);
  }

  resetAll () {
    for (const name of this.filters) {
      this.resets[name]();
    }

    this.search();
  }


  save (e) {
    const button = e.currentTarget;

    const exec = () => {
      button.disabled = true;

      window.$Gunfinder.fetch('/profile/searches', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: atob(this.resultsTarget.dataset.filter)
      });

      (() => {
        const text = button.innerHTML;
        button.innerHTML = button.dataset.successText;
        window.setTimeout(() => {
          button.innerHTML = text;
          button.disabled = false;
        }, 8000);
      })();
    };

    if (!window.$Gunfinder.user.signedIn) {
      this.appController.authController.open({ mode: 'search', callback: exec.bind(this) });
      return;
    }

    exec();
  }
}
